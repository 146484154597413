var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lazy-hydrate",
    { attrs: { "trigger-hydration": _vm.hydrate, never: "" } },
    [
      _c(
        "div",
        {
          class: [
            "single-step",
            {
              "single-step--local": _vm.LOCAL,
              "single-step--on-beige-background": _vm.onBeigeBackground,
            },
          ],
        },
        [
          _c("single-step-application", {
            attrs: { "on-beige-background": _vm.onBeigeBackground },
            on: {
              loaded: function ($event) {
                return _vm.loaded("single-step")
              },
            },
          }),
          _vm._v(" "),
          _vm.LOCAL
            ? _c("div", { staticStyle: { "padding-bottom": "1500px" } })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }