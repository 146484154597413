<template>
  <lazy-hydrate
    :trigger-hydration="hydrate"
    never
  >
    <div
      :class="['single-step', {
        'single-step--local': LOCAL,
        'single-step--on-beige-background': onBeigeBackground
      }]"
    >
      <single-step-application
        :on-beige-background="onBeigeBackground"
        @loaded="loaded('single-step')"
      />

      <!-- used to test sticky cta -->
      <div
        v-if="LOCAL"
        style="padding-bottom: 1500px;"
      />
    </div>
  </lazy-hydrate>
</template>

<script>
  import { arraysAreEqual, hasProp, hotjarTagRecording } from '@ocp-zmarta/zmarta-cl'
  import LazyHydrate from 'vue-lazy-hydration'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'SingleStep',
    components: {
      LazyHydrate,
      SingleStepApplication: () => import(/* webpackChunkName: 'application/cl/single-step' */
        '../../application/cl/single-step/SingleStep')
    },
    data: () => ({
      hydrate: false,
      loadComponents: {
        needed: [
          'mounted',
          'single-step'
        ],
        loaded: [],
        finished: false
      }
    }),
    computed: {
      ...mapGetters('router', ['getRoute', 'getQuery']),
      ...mapGetters('cl/formAlternatives', ['getFormAlternatives']),
      onBeigeBackground () {
        return this.getQuery?.['on-background'] === 'beige' || this.onArticlePage || this.onPartnerPage || this.onCampaignPage
      },
      onArticlePage () {
        return hasProp(this.getQuery, 'on-article-page')
      },
      onPartnerPage () {
        return hasProp(this.getQuery, 'on-partner-page')
      },
      onCampaignPage () {
        return hasProp(this.getQuery, 'on-campaign-page')
      }
    },
    async serverPrefetch () {
      await this.init()
    },
    async mounted () {
      await this.init()
      hotjarTagRecording('cl/form/single-step')

      await Promise.all([
        this.setField({ group: 'global', field: 'brandId', value: this.getRoute?.meta?.brandId }),
        this.setField({ group: 'global', field: 'brokerId', value: this.getRoute?.meta?.brokerId }),
        this.setField({ group: 'global', field: 'mediaBrokerId', value: this.getRoute?.meta?.mediaBrokerId }),
        this.setField({ group: 'global', field: 'affId', value: this.getRoute?.meta?.affId })
      ])

      if (this.getFormAlternatives?.loaded !== null) {
        this.fetchFormAlternatives().catch()
      }

      this.hydrate = true
      await this.loaded('mounted')
    },
    methods: {
      ...mapActions('form', ['setField']),
      ...mapActions('cl/formAlternatives', ['fetchFormAlternatives']),
      ...mapActions('cl/application', ['initApplication']),
      async init () {
        await this.initApplication({ type: 'singleStep' })
      },
      async loaded (component) {
        if (this.loadComponents.finished) return
        this.loadComponents.loaded.push(component)

        if (!arraysAreEqual(
          this.loadComponents.needed,
          this.loadComponents.loaded
        )) return

        this.loadComponents.finished = true
        this.$emit('loaded')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .single-step {
    width: 100%;
    min-height: auto;

    &--local {
      min-height: 100vh;
      background-color: color(beige);

      &.single-step--on-beige-background {
        background-color: color(white);
      }
    }
  }
</style>
